<template>
  <div>
    <b-form-group
      :label="label"
      :state="state"
    >
      <template
        v-if="label"
        v-slot:label
      >
        {{ label }}
        <span
          v-if="required"
          class="text-danger"
        >
          (*)
        </span>
      </template>
      <v-select
        id="cashbook"
        v-model="cashbook"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="name"
        :options="cashbooks"
        :selectable="(option) => option && !ignoreIds.includes(option.id)"
        :placeholder="isTingTong ? t('Hình thức thanh toán') : t('Sổ quỹ')"
        @input="cashbookChanged"
        @open="onOpen"
      >
        <template
          v-if="enableAdd && $can('create', 'cashbook')"
          #list-header
        >
          <li
            v-b-modal.modal-cashbook
            class="list-header d-flex align-items-center my-50"
          >
            <feather-icon
              icon="PlusIcon"
              size="16"
            />
            <span class="align-middle ml-25">{{ t('Thêm sổ quỹ') }}</span>
          </li>
        </template>
        <!-- eslint-disable-next-line vue/no-unused-vars  -->
        <template #no-options="{ search, searching, loading }">
          {{ t('Không có dữ liệu') }}
        </template></v-select>
      <small class="text-danger">{{ error }}</small>
    </b-form-group>
    <cashbook-modal
      v-if="enableAdd"
      :item="item"
      @refetch-data="fetchCashbooks"
      @on-item-created="onItemCreated"
    />
  </div>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue';
import vSelect from 'vue-select';
import useJwt from '@/auth/jwt/useJwt';
import moment from 'moment';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import CashbookModal from '../finance/cashbook/modal/CashbookModal.vue';

export default {
  components: {
    BFormGroup,
    vSelect,
    CashbookModal,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    state: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: Object,
      default: null,
    },
    enableAdd: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    ignoreIds: {
      type: Array,
      default: () => [],
    },
    apartmentId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isTingTong: process.env.VUE_APP_PROVIDER === 'tingtong',
      cashbook: this.defaultValue,
      cashbooks: [],
      item: {
        id: 0,
        type: 'bank',
        name: '',
        bankName: '',
        bankAddress: '',
        bankAccountHolder: '',
        bankAccountNumber: '',
        initialAmount: 0,
        initialDate: moment(new Date()).format('DD-MM-YYYY'),
      },
    };
  },
  watch: {
    apartmentId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.cashbook = null;
        this.cashbookChanged();
        if (newVal) {
          this.fetchCashbooks();
        } else {
          this.cashbooks = [];
        }
      }
    },
  },
  created() {
    // this.fetchCashbooks();
  },
  setup() {
    const { t } = useI18nUtils();
    return { t };
  },
  methods: {
    cashbookChanged() {
      this.$emit('input', this.cashbook);
    },
    onOpen() {
      if (this.cashbooks.length === 0) {
        this.fetchCashbooks();
      }
    },
    fetchCashbooks() {
      let filter = {};
      if (this.apartmentId && this.apartmentId > 0) {
        filter = { ...filter, apartmentId: this.apartmentId };
      }
      useJwt
        .selectItem('cashbook', { filter })
        .then(res => {
          this.cashbooks = res.data.items;
        })
        .catch(() => {
          this.cashbooks = [];
        });
    },
    onItemCreated(val) {
      this.cashbook = val;
      this.cashbookChanged();
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/vue-select.scss';
</style>
